import React, { useContext, useEffect } from "react";
import { useLocation, Route, Redirect } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { EventContext } from "../context/EventContext";

export const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const location = useLocation();
  const { hasAccess } = useContext(AuthContext);
  const { eventID, setEventID } = useContext(EventContext);

  // Set the event ID if it's not set
  useEffect(() => {
    const event = location.pathname.split("/")[1];
    if (!eventID) setEventID(decodeURI(event));
  }, [location, eventID, setEventID]);

  if (typeof hasAccess === 'undefined') return null;

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        // User must have access to this event to see any pages
        !!hasAccess ? (
          <RouteComponent {...routeProps} />
        // If the user does not have access, redirect them to the login page
        ) : (
          <Redirect to={`/${location.pathname.split("/")[1]}/login`} />
        )
      }
    />
  );
};
