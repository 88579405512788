import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { EventContext } from "../context/EventContext";
import { AuthContext } from "../context/AuthContext";
import { withRouter, Redirect } from "react-router";
import { signInToEvent } from "../auth";

const Login = ({ history }) => {
  const {
    eventName,
    startDate,
    endDate,
    brandColor,
    glamourShot,
    eventLogo,
    logo,
    active,
    existingEvent,
    validEvent,
    setEventID,
    eventID,
  } = useContext(EventContext);
  const { user, setUser, hasAccess } = useContext(AuthContext);
  const errorRef = useRef("");
  const [loginButtonText, setLoginButtonText] = useState("Login");
  const [disabledLoginButton, setDisabledLoginButton] = useState(false);

  useEffect(() => {
    document.body.className = "login";
    return () => {
      document.body.className = "";
      errorRef.current = "";
    };
  }, []);

  // Set the event ID if it's not set
  useEffect(() => {
    if (eventID) return;
    const event = history.location.pathname.split("/")[1];
    setEventID(decodeURI(event));
  }, [history, eventID, setEventID]);

  const submitHandler = useCallback(
    (event) => {
      if (!eventID) return;
      event.preventDefault();
      setLoginButtonText("Logging in...");
      setDisabledLoginButton(true);
      const { email, password } = event.target.elements;
      // Sign in with Firebase
      signInToEvent(email.value, password.value, eventID)
        .then((user) => {
          errorRef.current = "";
          // Tell the auth context that a user signed in
          setUser(user);
          // history.push(`/${eventID}/welcome`);
        })
        .catch((error) => {
          console.log(`Error signing in: ${error}`);
          errorRef.current = error.message;
          alert(error);
          setLoginButtonText("Login");
          setDisabledLoginButton(false);
        });
    },
    [eventID, setUser]
  );

  const renderDates = useCallback(() => {
    if (!startDate || !endDate) return;
    // Dates are stored as UTC dates from Admin tool, but it's okay because the time doesn't matter
    const startDateString = `${startDate.getMonth() + 1}.${startDate.getUTCDate()}.${startDate.getYear() - 100}`;
    const endDateString = `${endDate.getMonth() + 1}.${endDate.getUTCDate()}.${endDate.getYear() - 100}`;
    const oneDay = startDate.getTime() === endDate.getTime();
    return (
      <span>
        {startDateString}
        {!oneDay && ` - ${endDateString}`}
      </span>
    );
  }, [startDate, endDate]);

  if (existingEvent == null || validEvent == null || hasAccess == null ) return null;
  // Event id doesn't exist
  if (existingEvent === false) return <Redirect to={`/${eventID}/`} />;
  // Event doesn't contain all fields
  if (validEvent === false) return <Redirect to={`/${eventID}/invalid/`} />;
  // Event is no longer running
  if (!active) return <Redirect to={`/${eventID}/outofservice/`} />;
  // User is already logged in
  if (user && user.uid && hasAccess) {
      return <Redirect to={`/${eventID}/welcome/`} />;
  }

  return (
    <div className="loginMain fade-in">
      <div className="loginPane">
        <div className="loginTop">
          <img src={eventLogo} alt="Event logo" />
          <div
            style={{
              width: "220px",
              margin: "0 auto 76px auto",
              borderBottom: "1px solid black",
            }}
          ></div>
          <div className="loginIntro">
            {eventName}
            {renderDates()}
          </div>
        </div>

        <form
          name="loginForm"
          onSubmit={submitHandler}
          style={{ backgroundColor: `${brandColor}` }}
          autoComplete="off"
        >
          {" "}
          {/* retry message */}
          <div className="retry-message">
            <label>{errorRef.current}</label>
          </div>
          {/* email */}
          <input
            name="email"
            type="text"
            className="validated-field"
            placeholder="Email"
            required
            autoFocus
          />{" "}
          <br />
          {/* password */}
          <input
            name="password"
            type="password"
            className="validated-field"
            placeholder="Password"
            required
          />{" "}
          <br />
          {/* submit */}
          <button type="submit"
            disabled={disabledLoginButton}
            className={"button"}>
            {loginButtonText}
            <span></span>
          </button>
        </form>
      </div>

      <div className="loginPic" style={{ backgroundImage: `url("${glamourShot}")` }}>
        <i style={{ background: `url(${logo}) no-repeat center`}} className="logo"></i>
      </div>
    </div>
  );
};

export default withRouter(Login);
