import { removeProductFromCart } from "../firestore";
import { omit } from "../helpers";

export const TOGGLE_CART = "TOGGLE_CART";
export const OPEN_CART = "OPEN_CART";
export const CLOSE_CART = "CLOSE_CART";
export const UPDATE_TOTAL = "UPDATE_TOTAL";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const ADD_PAYPAL = "ADD_PAYPAL";
export const SET_CART_PRODUCT = "SET_CART_PRODUCT";
export const SET = "SET";

// Open the cart UI after a product is added
const closeCart = (state) => {
  return { ...state, open: false, toggled: true };
};

// Open the cart UI after a product is added
const openCart = (newestProduct, state) => {
  return { ...state, open: true, toggled: false, newestProduct };
};

// Open the cart UI by toggling with a click
const toggleCart = (state) => {
  return { ...state, open: !state.open, toggled: true };
};

// Add a product to the cart's products
const addToCart = (cartProduct, productList, state) => {
  // Increment the quantity
  const newProduct = {
    product: cartProduct.product.xID,
    size: cartProduct.size,
    quantity: cartProduct.quantity + 1,
    embroidery: cartProduct.embroidery,
  };
  const newState = {
    ...state,
    products: {
      ...state.products,
      [cartProduct.id]: newProduct,
    },
  };
  return newState;
};

// Remove a product to the cart's products
const removeFromCart = (cartProduct, state) => {
  const newState = {
    ...state,
    products: omit(state.products, cartProduct.id),
  };
  removeProductFromCart(cartProduct.id, newState);
  return newState;
};

// Adds a PayPal payment record to the cart
const addPayPal = (amount, state) => {
  const newPayPalAmount = !!state.payPalApplied ?
    parseFloat(state.payPalApplied) + parseFloat(amount) :
    parseFloat(amount);

  const newState = {
    ...state,
    payPalApplied: newPayPalAmount,
  };
  return newState;
};

// Set the cart product items
const setCartProduct = (id, cartProduct, state) => {
  const newState = {
    ...state,
    products: {
      ...state.products,
      [id]: {
        ...state.products[id],
        ...cartProduct,
      },
    },
  };
  console.log(newState)
  return newState;
};

// Calculate and update the total price and budget
const updateTotal = (products, state) => {
  if (Object.keys(products).length === 0) return state;
  const newTotal = Object.values(state.products).reduce((total, {quantity, product: xID}) => {
    const product = products[xID];
    if (!product) {
      // Remove a product from the cart if it's no longer in the product list
      console.log(`${xID} doesn't exist in the product list, removing from cart`);
      const id = Object.keys(state.products)
        .find(key => state.products[key].product === xID);
      state = removeFromCart({id}, state);
      return total;
    }
    return total + product.price * quantity;
  }, 0);
  const change = state.total - newTotal;
  const newState = {
    ...state,
    total: newTotal,
    balance: state.balance + change,
  };
  return newState;
};

export const cartReducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_CART:
      return toggleCart(state);
    case OPEN_CART:
      return openCart(action.cartProduct, state);
    case CLOSE_CART:
      return closeCart(state);
    case UPDATE_TOTAL:
      return updateTotal(action.products, state);
    case ADD_PRODUCT:
      return addToCart(action.cartProduct, action.productList, state);
    case REMOVE_PRODUCT:
      return removeFromCart(action.cartProduct, state);
    case ADD_PAYPAL:
      return addPayPal(action.amount, state);
    case SET_CART_PRODUCT:
      return setCartProduct(action.id, action.cartProduct, state);
    case SET:
      return { ...action.state };
    default:
      return state;
  }
};
