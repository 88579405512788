import React, { createContext, useState, useEffect, useCallback } from "react";
import { dateInRange, getMonogramTapes } from "../helpers";
import { getCustomer, getEventData, eventExists } from "../firestore";

export const EventContext = createContext();

export const EventProvider = ({ children }) => {
  const [active, setActive] = useState(true);
  const [eventID, setEventID] = useState();
  const [hideMobileNav, setHideMobileNav] = useState(true);
  const [eventData, setEventData] = useState(null);
  const [eventDocID, setEventDocID] = useState();
  const [contactEmail, setContactEmail] = useState();
  const [products, setProducts] = useState({});
  const [categories, setCategories] = useState([]);
  const [customerID, setCustomerID] = useState();
  const [customer, setCustomer] = useState();
  const [brandColor, setBrandColor] = useState("");
  const [budget, setBudget] = useState();
  const [customUnits, setCustomUnits] = useState();
  const [overBudgetRules, setOverBudgetRules] = useState();
  const [payPalPayee, setPayPalPayee] = useState();
  const [dropShipCharge, setDropShipCharge] = useState();
  const [forceDropShip, setForceDropShip] = useState(false);
  const [allowOverage, setAllowOverage] = useState();
  const [maxOverage, setMaxOverage] = useState(0);
  const [unlimitedOverage, setUnlimitedOverage] = useState();
  const [unlimitedBudget, setUnlimitedBudget] = useState(false);
  const [disclaimer, setDisclaimer] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [goLiveDate, setGoLiveDate] = useState();
  const [lastOrderDate, setLastOrderDate] = useState();
  const [lastLogoDate, setLastLogoDate] = useState();
  const [promiseDate, setPromiseDate] = useState();
  const [poNumberPrefix, setPoNumberPrefix] = useState();
  const [featuredProduct, setFeaturedProduct] = useState("");
  const [logo, setLogo] = useState("");
  const [embroideryLogo, setEmbroideryLogo] = useState("");
  const [monogramTapes, setMonogramTapes] = useState([]);
  const [eventLogo, setEventLogo] = useState("");
  const [eventName, setEventName] = useState("");
  const [participants, setParticipants] = useState();
  const [productList, setProductList] = useState();
  const [promoImage, setPromoImage] = useState("");
  const [promoText, setPromoText] = useState("");
  const [promoTitle, setPromoTitle] = useState("");
  const [glamourShot, setGlamourShot] = useState("");
  const [sortingPath, setSortingPath] = useState(null);
  const [welcomeLetter, setWelcomeLetter] = useState([]);
  const [existingEvent, setExistingEvent] = useState();
  const [validEvent, setValidEvent] = useState();

  const fetchEventData = useCallback(() => {
    // Fetch event data from Firestore
    getEventData(eventID).then(doc => {
      setEventDocID(doc.id);
      setEventData(doc.data());
    });
  }, [eventID, setEventData]);

  // Set the event ID from the URL
  useEffect(() => {
    if (!eventID) return;
    eventExists(eventID).then(exists => {
      setExistingEvent(exists);
      if (exists) fetchEventData();
    });
  }, [eventID, fetchEventData, setExistingEvent]);

  // Set the monogram tapes
  useEffect(() => {
    getMonogramTapes().then(tapes => {
      setMonogramTapes(tapes);
    });
  }, []);

  useEffect(() => {
    if (!eventData) return;
    setBrandColor(eventData.brandColor);
    setBudget(eventData.budget >= 0 ? eventData.budget : 0);
    setContactEmail(eventData.contactEmail);
    setCustomUnits(eventData.priceUnits);
    setOverBudgetRules(eventData.overBudgetRules);
    setPayPalPayee(eventData.payPalPayee);
    setDropShipCharge(eventData.dropShipCharge);
    setForceDropShip(eventData.forceDropShip);
    setAllowOverage(eventData.maxBudgetOverage > 0);
    setMaxOverage(eventData.maxBudgetOverage);
    setUnlimitedOverage(eventData.maxBudgetOverage < 0);
    setUnlimitedBudget(eventData.budget < 0);
    setCustomerID(eventData.customer);
    getCustomer(eventData.customer).then(result => {
      setCustomer(result);
    });
    setDisclaimer(eventData.disclaimer);
    setStartDate(new Date(eventData.startDate.seconds * 1000));
    setEndDate(new Date(eventData.endDate.seconds * 1000));
    setGoLiveDate(eventData.goLiveDate);
    setLastOrderDate(eventData.lastOrderDate);
    setLastLogoDate(eventData.lastLogoDate);
    setPromiseDate(eventData.promiseDate);
    setPoNumberPrefix(eventData.poNumberPrefix);
    setActive(dateInRange(eventData.goLiveDate, eventData.lastOrderDate));
    setFeaturedProduct(eventData.featuredProduct);
    setLogo(eventData.logo);
    setEmbroideryLogo(eventData.embroideryLogo);
    setEventLogo(eventData.eventLogo);
    setEventName(eventData.name);
    setParticipants(eventData.participants);
    setProductList(eventData.productList);
    setPromoImage(eventData.promoImage);
    setPromoText(eventData.promoText);
    setPromoTitle(eventData.promoTitle);
    setGlamourShot(eventData.glamourShot);
    setWelcomeLetter(eventData.welcomeLetter);
    setValidEvent(eventData.validated);
  }, [eventData]);

  return (
    <EventContext.Provider
      value={{
        eventID,
        setEventID,
        eventDocID,
        active,
        contactEmail,
        customer,
        sortingPath,
        setSortingPath,
        hideMobileNav,
        setHideMobileNav,
        eventData,
        productList,
        products,
        setProducts,
        categories,
        setCategories,
        customerID,
        setCustomer,
        brandColor,
        budget,
        setBudget,
        unlimitedBudget,
        customUnits,
        payPalPayee,
        allowOverage,
        maxOverage,
        unlimitedOverage,
        disclaimer,
        startDate,
        endDate,
        goLiveDate,
        lastOrderDate,
        lastLogoDate,
        promiseDate,
        poNumberPrefix,
        eventLogo,
        embroideryLogo,
        monogramTapes,
        featuredProduct,
        logo,
        eventName,
        overBudgetRules,
        dropShipCharge,
        forceDropShip,
        participants,
        promoImage,
        promoText,
        promoTitle,
        glamourShot,
        welcomeLetter,
        existingEvent,
        validEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};
